import React from 'react';

const Signin = React.lazy(() => import('../../app/headspace/Signin'));

export default function HeadspaceSignin() {
  return (
    <React.Suspense fallback={<div />}>
      <Signin />
    </React.Suspense>
  );
}
